import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilityDetailViewHistory } from './models/facility-detail-view-history';
import { FacilityDetailViewHistoryGetApiResponse, FacilityDetailViewHistoryUpdateApiResponse } from './models/facility-detail-view-history-api-response';
import { FacilityDetailViewHistoryGetApiParameter } from './models/facility-detail-view-history-api-parameter';

const BASE_URL = '/v1/facility/facilityDetailViewHistory';
const UPDATE_URL = `${BASE_URL}/update`;

/**
 * [満空情報]施設詳細閲覧履歴のプロバイダー.
 */
@Injectable()
export class FacilityDetailViewHistoryServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilityDetailViewHistory> = new BehaviorSubject<FacilityDetailViewHistory>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): FacilityDetailViewHistory {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilityDetailViewHistory) {
    this.sessionSource.next(session);
  }

  /**
   * 設詳細閲覧履歴一覧取得処理.
   * 
   * @param facilityId 施設ID
   * @param startDt 検索範囲開始日時
   * @param endDt 検索範囲終了日時
   * @returns Observable<FacilityDetailViewHistoryGetApiResponse>
   */
  public getHistory(startDt: number, endDt: number, facilityId?: number): Observable<FacilityDetailViewHistoryGetApiResponse> {
    const params = { facilityId: facilityId, startDt: startDt, endDt: endDt } as FacilityDetailViewHistoryGetApiParameter;
    if (facilityId) {
      params["facilityId"] = facilityId;
    }
    return this.post(BASE_URL, params);
  }

  /**
   * 設詳細閲覧履歴の更新処理.
   * 
   * @param id 履歴ID
   * @param facilityId 施設ID
   * @param userId ユーザーID
   * @param beginViewDt 閲覧開始日時
   * @param endViewDt 閲覧終了日時
   * @returns Observable<FacilityDetailViewHistoryUpdateApiResponse>
   */
  public update(id: number, facilityId: number, userId: number, beginViewDt: number, endViewDt?: number): Observable<FacilityDetailViewHistoryUpdateApiResponse> {
    const params = { 
      id: id,
      facilityId: facilityId,
      userId: userId,
      beginViewDt: beginViewDt,
      endViewDt: endViewDt
     } 
    if (endViewDt) {
      params["endViewDt"] = endViewDt;
    }
    return this.post(UPDATE_URL, params);
  }
}
