import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { StaticTableLoader } from '../../app/services/StaticTableLoader';
import { UserV2ServiceProvider } from './user-v2-service';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { StationForSearch } from '../../app/services/servicer/models/stationForSearch';
import { UpdatesAlertService } from '../../app/services/updates-alert.service';

/**
 * キャッシュ更新サービスプロバイダー.
 */
@Injectable()
export class CacheUpdateService {

  // 24時間（ミリ秒）
  private readonly ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

  // 現在設定されている更新間隔
  private currentInterval = this.ONE_DAY_IN_MILLISECONDS;

  // 更新完了を検知するトリガー
  public completeSubject = new Subject<void>();

  constructor(
    private userV2Service: UserV2ServiceProvider,
    private staticTable: StaticTableLoader,
    private toast: ToastController,
    private updateAlert: UpdatesAlertService
  ) {
    this.staticTable = new StaticTableLoader(this.userV2Service);
  }

  /**
   * 更新間隔を設定する（翌日0時）
   *
   */
  public setTimerForMidnight() {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const initialDelay = tomorrow.getTime() - now.getTime();

    this.currentInterval = this.ONE_DAY_IN_MILLISECONDS;
    timer(initialDelay, this.currentInterval).subscribe(() => {
      this.updateAlert.checkForUpdateVersion().then(()=>{
        this.reloadServerCache();
      });
    });
  }

  /**
   * サーバーキャッシュ更新処理
   *
   */
  public reloadServerCache() {
    this.staticTable.initStationList().subscribe(async (result: boolean) => {
      if (result) {
        // 設定中のステーションコードを取得
        if (environment.stationTables.stationSession) {
          const stationCode = environment.stationTables.stationSession.code;
          if (stationCode) {
            const targetStation = environment.constTables.userStations.find((station: StationForSearch) => station.code === stationCode);
            this.staticTable.getStationTable(targetStation).subscribe(async (saveResult: boolean) => {
              if (saveResult) {
                (await this.toast.create({
                  message: '読み込み成功',
                  position: 'top',
                  duration: 2000
                })).present();

                // サーバーキャッシュ更新完了を通知
                this.completeSubject.next();
              } else {
                (await this.toast.create({
                  message: '読み込み失敗',
                  position: 'top',
                  duration: 2000
                })).present();
              }
            });
            //駅コード未設定の場合は、何も処理せず読み込み成功と表示
          } else {
            (await this.toast.create({
              message: '読み込み成功',
              position: 'top',
              duration: 2000
            })).present();

            // サーバーキャッシュ更新完了を通知
            this.completeSubject.next();
          }
        }
      } else {
        (await this.toast.create({
          message: '読み込み失敗',
          position: 'top',
          duration: 2000
        })).present();
      }
    });
  }

  /**
   * 外部の満空状態値更新処理により意図しない満空状態値が設定されたときにポップアップを表示
   *
   */
  public async popUpWhenUpdateFailedByExternalSystems(): Promise<void> {
    (await this. toast.create({
      message: '外部で施設の満空状態が更新されました。再度施設の満空状態の更新してください。',
      position: 'top',
      duration: 5000
    })).present();
  }
}
