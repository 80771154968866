import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FacilitySetteingGetApiParameter } from './models/facility-setteing-api-parameter';
import { FacilitySetteingGetApiResponse } from './models/facility-setteing-api-response';
import { Facility } from './models/facility';

import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';

const BASE_URL = '/v1/facility/facilitySetting';

/**
 * [満空情報]施設設定のプロバイダー.
 */
@Injectable()
export class FacilitySettingServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(null);
  private facility: Facility[];

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): Facility {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: Facility) {
    this.sessionSource.next(session);
  }

  /**
   * 施設設定を取得する.
   */
  getFacilitySetting(): Facility[] {
    return this.facility;
  }

  /**
   * 施設設定をを削除する.
   */
  deleteFacilitySetting() {
    this.facility = [];
  }

  /**
   * 施設設定を保存する.
   */
  saveFacilitySetting(facility: Facility[]) {
    this.facility = facility;
  }

  /**
   * 施設設定一覧取得処理.
   * 
   * @returns Observable<FacilitySetteingGetApiResponse>
   */
  list(): Observable<FacilitySetteingGetApiResponse> {
    const params = {} as FacilitySetteingGetApiParameter;
    return this.post(BASE_URL, params);
  }

  /**
   * 施設設定個別取得処理.
   * 
   * @param id 施設ID
   * @returns Observable<FacilitySetteingGetApiResponse>
   */
  one(id: number): Observable<FacilitySetteingGetApiResponse> {
    const params = { id: id } as FacilitySetteingGetApiParameter;
    return this.post(BASE_URL, params);
  }
}
